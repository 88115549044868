import conf from "../_config";

const { c } = conf;

export default  function () {
 $('.js-popup-btn').each((i, el) => {
    $(el).click(evt => {
      evt.preventDefault();
      const target  = $(el).data('target');

      $(`[data-popup="${target}"]`).toggleClass(c.isActive);

    });
  });


}
