import conf from "../_config";

const { c } = conf;

export default function () {
  $('.js-sort-btn').each((i, el) => {
    $(el).click(evt => {
      evt.preventDefault();
      const $parent = $(el).closest('.js-sort-container');
      const $list = $parent.find('.js-sort-list');

      $(el).toggleClass(c.isActive);

      if ($(el).hasClass(c.isActive)) {
        $list.addClass(c.isActive);
      } else {
        $list.removeClass(c.isActive);
      }

    });
  });




  $('.js-order-btn').each((i, el) => {
    $(el).click(evt => {
      evt.preventDefault();
      const $target = $(el).closest('.js-form-container').find('.js-order-form');
      $target.toggleClass(c.isActive);
    });
  });

  $('.js-search-input').each((i, el) => {
    $(el).blur(() => {
      if ($(el).val().trim()) {
        $(el).addClass(c.isFilled);
      } else {
        $(el).removeClass(c.isFilled);
      }
    });
  });
}
