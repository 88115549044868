import videojs from 'video.js';
import 'videojs-youtube';

const initVideos = () => {
  $('.video-js').each((i, el) => {

    let opts = {
      controls: true,
      autoplay: false,
      preload: 'auto'
    };

    if ($(el).hasClass('youtube')) {
      opts = {
        techOrder: ['youtube'],
        sources: [
          {
            type: 'video/youtube',
            src: $(el).data('url')
          }
        ]
      };
    }

    const player = videojs(el, opts);

    player.on('touchstart', () => {
      if (player.paused()) {
        player.play();
      } else {
        player.pause();
      }
    });

  });
};

export default initVideos;
