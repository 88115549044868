import conf from "../_config";

const { c } = conf;

export default function () {
  const $menuBtn = $('.js-menu-btn');
  const $menu = $('.js-menu');

  $menuBtn.click(evt => {
    evt.preventDefault();
    $menuBtn.toggleClass(c.isActive);

    if ($menuBtn.hasClass(c.isActive)) {
      $menu.addClass(c.isActive);
    } else {
      $menu.removeClass(c.isActive);
    }

  });
}
