export default () => {
    const isCataloguePage = $('.product-catalogue').length;

    if (!isCataloguePage) return;

    const setCookie = (name,value) => {
        document.cookie = `${name}=${  value || ''}`;
    };

    const getCookie = name =>  {
        const nameEQ = `${name  }=`;
        const ca = document.cookie.split(';');
        for(let i=0;i < ca.length;i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    };

    const $scrollHolder = $('.js-scroll-holder-content');

    const cookieName = 'js-last-scroll-position';
    const {href} = window.location;
    const cookie = getCookie(cookieName);
    let pages = null;

    if (cookie) {
        pages = JSON.parse(cookie);

        let pageFound = false;

        pages.forEach(el => {
            if (el.href === href && el.scrollPosition > 0.5 * $(window).height()) {
                pageFound = true;
                $scrollHolder.scrollTop(el.scrollPosition);
                $('.products__link').each((i, link) => {
                  if ($(link).offset().top < $(window).height()) {
                       $(link).removeClass('js-triggered-anim').css({
                      opacity: 1
                    });
                 }
                });
            }
        });

        if (!pageFound) {
            pages.push({
                href
            });
        }
    } else {
        pages = [{
            href
        }];
    }


    window.addEventListener('beforeunload', () => {
        pages.forEach((el, i) => {
            if (el.href === href) {
                pages[i].scrollPosition = $scrollHolder.scrollTop();
            }
        });
        setCookie(cookieName, '');
        setCookie(cookieName, JSON.stringify(pages));
    });


}
