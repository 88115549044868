import PerfectScrollbar from 'perfect-scrollbar';
import conf from "../_config";

const { d } = conf;

export default function () {
   $('.js-scrollable').each((i, el) => {
    const opts = {
      wheelSpeed: 0.5,
      wheelPropagation: true
    };

    if ($(el).hasClass('scroll-holder__content')) {
      opts.suppressScrollX = true;
    }

    const ps = new PerfectScrollbar(el, opts);
    ps.update();
    d.win.on('resize', () => {ps.update()});
  });
}
