import Inputmask from "inputmask";

export default function () {


 $('input[type="tel"]').each((i, field) => {
    const mask = $(field).data('mask');
    if (!mask) return;

    const telMask = new Inputmask({
      mask,
      placeholder: ' ',
      showMaskOnHover: false
    });


   telMask.mask(field);

  });

}
