
import svg4everybody from 'svg4everybody';
import objectFitImages from 'object-fit-images';

import conf from './_config';
import sayHello from './lib/sayHello';
import initMenu from './components/menu';
import initNestedLinks from './components/nestedLinks';
import initMaps from './components/map';
import initScrolls from './components/scrollable';
import initPopups from './components/popups';
import initProductPage from './components/product';
import initToggleBtns from './components/toggleBtns';
import initFormComponents from './components/formComponents';
import initAnimations from './components/animations';
import stickyGroups from './components/stickyGroups';
import checkScrollPosition from './components/scrollPosition';
import initVideos from './components/video';

const { d } = conf;
// const isTouchDevice = detectTouch();
const isIE = !!document.documentMode;
sayHello();

$(() => {
  svg4everybody();
  objectFitImages($('img'));
  if (isIE) d.body.addClass('is-IE');
  initVideos();
  initMenu();
  initNestedLinks();
  initMaps();
  initScrolls();
  initPopups();
  initProductPage();
  initToggleBtns();
  initFormComponents();
  stickyGroups();
  checkScrollPosition();
  initAnimations();
});
