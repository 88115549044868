import { TimelineMax } from 'gsap';


// import conf from '../_config';


// const { d } = conf;


function sequencedElemIsVisible (el, $parent) {
  const parentTop = $parent.offset().top + $parent.height();
  const parentLeft = $parent.offset().left + $parent.width();
  const elemTop = $(el).offset().top + $(el).height();
  const elemLeft = $(el).offset().left - 20;
  const yVisibility = elemTop - 0.8 * $(el).height()  < parentTop;
  const xVisibility =  elemLeft < parentLeft;


  return yVisibility && xVisibility;
}



function triggerSequenceAnimation ($container) {
  const $actualContainer = $container.hasClass('js-products-anim') ? $container : $container.closest('.js-scroll-holder-content');



  function runSequenceAnimation() {
    const elems = $container.find('.js-triggered-anim').get();

    if (!elems.length) {
      $actualContainer.off('scroll', runSequenceAnimation);
    }
    elems.sort((a, b) => {
      return $(a).offset().top - $(b).offset().top;
    });

    const elemsToAnimate = [];

    elems.forEach(el => {
        if (sequencedElemIsVisible(el, $actualContainer)) {
          elemsToAnimate.push(el);
        }
      });

    if (!elemsToAnimate.length) return;

    $(elemsToAnimate).removeClass('js-triggered-anim');

    const tl = new TimelineMax();

    switch ($container.data('dir')) {
      case 'btt':
        tl.staggerFromTo(elemsToAnimate, 1.5, {yPercent: 100, opacity: 0}, {yPercent: 0, opacity: 1, onComplete() {
         $container.addClass('is-animated');
        }}, 0.2);
        break;
      case 'bloglist':
        elemsToAnimate.forEach((el, i) => {
        const $parent = $(el).closest('.js-triggered-anim-anchor');
        const $img = $parent.find('.js-anim-img');
        const $text = $parent.find('.js-anim-text');

        tl.fromTo( $parent, 0.25, {opacity: 0}, {opacity: 1}, i);
        tl.fromTo($img, 1, {opacity: 0, xPercent: -20}, {opacity: 1, xPercent: 0}, i);
        tl.fromTo($text, 1, {opacity: 0, xPercent: -20}, {opacity: 1, xPercent: 0, onComplete() {
              $container.addClass('is-animated');
            }}, i);
        });
        break;
      case 'text':
        tl.staggerFromTo(elemsToAnimate, 1.5, {x: -20, opacity: 0}, {x: 0, opacity: 1, onComplete() {
          $container.addClass('is-animated');
          $(elemsToAnimate).addClass('js-scroll-parallax');
          // initScrollParallax($(elemsToAnimate));
        }}, 0.2);
        break;
        // ltr
      default:
        tl.staggerFromTo(elemsToAnimate, 1, {xPercent: 120, opacity: 0}, {xPercent: 0, opacity: 1, onComplete() {
          $container.addClass('is-animated');
        }}, 0.4);

    }
  }
  runSequenceAnimation();
  $actualContainer.on('scroll', runSequenceAnimation);
}

function triggerFooterAnimation() {
  $('.js-footer-inner').removeClass('js-anim');
  const tl = new TimelineMax();
  tl.fromTo('.js-f-col-l', 1.5,{xPercent: -120}, {xPercent: 0}, 'one');
  tl.fromTo('.js-f-col-r', 1.5,{xPercent: 120}, {xPercent: 0}, 'one');

}

function checkFooter() {
  if ($('.footer').data('animated')) {
     $('.js-scroll-holder-content').off('scroll', checkFooter);
  }

  if ($('.js-footer-inner').offset().top < $(window).height()) {

    triggerFooterAnimation();
    $('.footer').data('animated', true);
   $('.js-scroll-holder-content').off('scroll', checkFooter);
  }

}


function checkTitles () {
  $('.js-block-title').each((i, el) => {
    if ($(el).offset().top < $(window).height()) {
      $(el).find('.js-anim').removeClass('js-anim');
    }

  });
}

function checkFromLeft() {
  $('.js-from-left').each((i, el) => {
    if ($(el).offset().top - 0.75 * $(window).height() < 0) {
      $(el).removeClass(' js-from-left');
    }
  });
}



function startAnimations() {
  $('.js-text-anim .text-inner > *').addClass('js-triggered-anim');

  const $testResultContent = $('.test-result');
  if ($testResultContent.length) {
    const $$items = $testResultContent.find('.product-list__item');
    if ($$items.length === 1) {
      $testResultContent.addClass('is-single');
    }
  }



  $('.out').removeClass('is-invisible');
  checkFooter();
  checkTitles();
  checkFromLeft();

  $('.js-scroll-holder-content').on('scroll', checkFooter);
  $('.js-scroll-holder-content').on('scroll', checkTitles);
  $('.js-scroll-holder-content').on('scroll', checkFromLeft);



  const headerStaggerElems = $('.js-header-anim').get();
  const tl = new TimelineMax();
  tl.fromTo('.js-anim-logo', 1.5,{xPercent: -120}, {xPercent: 0}, 'one');
  tl.fromTo('.js-anim-btn', 1.5,{xPercent: 120}, {xPercent: 0}, 'one');
  tl.staggerFromTo(headerStaggerElems, 1.5, {xPercent: 120, opacity: 0}, {xPercent: 0, opacity: 1, onComplete() {
    triggerSequenceAnimation($('.js-catalogue-anim'));
    triggerSequenceAnimation($('.js-team-anim'));
    triggerSequenceAnimation($('.js-bloglist-anim'));
    triggerSequenceAnimation($('.js-search-anim'));
    setTimeout(() => {
      $('.out').addClass('animations-completed');
    }, 500);
  }}, 0.5, "one");
  tl.set('.js-header-title', {className:"-=js-anim"}, 'one')
  tl.set('.js-header-title-text', {className:"-=js-anim"}, 'one');




  tl.fromTo('.js-test-anim:nth-child(even)', 1.5, {xPercent: 120, opacity: 0}, {xPercent: 0, opacity: 1}, 2)
  tl.fromTo('.js-test-anim:nth-child(even) .symbol', 1.5, {xPercent: 350, opacity: 0}, {xPercent: 0, opacity: 1}, 2.5)
   tl.fromTo('.js-test-anim:nth-child(odd)', 1.5, {xPercent: -120, opacity: 0}, {xPercent: 0, opacity: 1}, 2)
   tl.fromTo('.js-test-anim:nth-child(odd) .symbol', 1.5, {xPercent: -350, opacity: 0}, {xPercent: 0, opacity: 1}, 2.5)



  tl.fromTo('.js-hero-bg', 3,{opacity: 0}, {opacity: 1}, 'one');
  tl.set('.js-hero-title', {className:"-=js-anim"}, 0.5)
  tl.fromTo('.js-hero-btn', 2, {yPercent: 120, opacity: 0}, {yPercent: 0, opacity: 1}, 1.5);


  // test results
  tl.fromTo('.test-result__content', 1.5, {xPercent: 120, opacity: 0}, {xPercent: 0, opacity: 1}, 2);
  tl.fromTo('.test-result__image', 1.5, {xPercent: -120, opacity: 0}, {xPercent: 0, opacity: 1,
    onComplete() {
      $('.test-result__image').removeClass('js-anim');
    }
    }, 2);
  tl.fromTo('.test-result__image .symbol', 1.5, {xPercent: -350, opacity: 0}, {xPercent: 0, opacity: 1}, 2.5);


  tl.fromTo('.product-catalogue__left-inner', 1.5, {xPercent: -120, opacity: 0}, {xPercent: 0, opacity: 1, onStart() {
      triggerSequenceAnimation($('.js-product-catalogue-anim'));
    }}, 2);
  tl.fromTo('.js-sort-btn, .sort-panel__filters-btn', 1.5, {xPercent: 120, opacity: 0}, {xPercent: 0, opacity: 1}, 2);


  // contacts
   tl.fromTo('.contacts__left', 1.5, {xPercent: -120, opacity: 0}, {xPercent: 0, opacity: 1}, 2.5);
   tl.fromTo('.contacts__right', 1.5, {xPercent: 120, opacity: 0}, {xPercent: 0, opacity: 1}, 2.5);

   // product
   tl.fromTo('.product__left', 1.5, {xPercent: -120, opacity: 0}, {xPercent: 0, opacity: 1, onStart() {
    $('.product__title').removeClass('js-anim');

   }, onComplete() {
    $('.product__left').removeClass('is-invisible');
    setTimeout(() => {
      $('.product__img').addClass('is-active');
    }, 1000);

   }}, 2.5);

   tl.fromTo('.product__right-wrapper', 1.5, {xPercent: -10, opacity: 0}, {xPercent: 0, opacity: 1}, 3);

   tl.fromTo('.js-order-btn', 1, {yPercent: 100, opacity: 0}, {yPercent: 0, opacity: 1}, 4.5);



    tl.fromTo('.article__img-holder', 1.5, {xPercent: -120, opacity: 0}, {xPercent: 0, opacity: 1, onStart() {
      triggerSequenceAnimation($('.js-text-anim'));
    }}, 2);



    // $('.js-text-anim').each((i, el) => {
    //   const $parent =  $(el).closest('.js-scrollable');
    //   const $article = $(el).closest('.js-article')
    //   $parent.on('ps-scroll-up', () => doTextParallax($parent, $article, 'up'));
    //   $parent.on('ps-scroll-down', () => doTextParallax($parent, $article, 'down'));

    // });
}




export default function () {
  const $preloader = $('.js-preloader');

  if (!$preloader.length) {
    startAnimations();
    return;
  }

  const activeIndex = 1;
  const $logos = $('.js-preloader-logo');






  function swapLogos(index) {

    if ($logos[index]) {
      setTimeout(() => {
        $logos.addClass('is-invisible');
        $($logos[index]).removeClass('is-invisible');
        swapLogos(index + 1);
      }, 250);
    } else {
      setTimeout(() => {
         $preloader.fadeOut(startAnimations);
      }, 100);

    }
  }

  swapLogos(activeIndex);

}
