/* eslint-disable */
import Swiper from 'swiper/dist/js/swiper';
import objectFitImages from 'object-fit-images';
import { TweenMax } from 'gsap';

import conf from "../_config";

const { c } = conf;

export default function () {

  if ($('.js-product').length) $('.scroll-holder').addClass('scroll-holder_product');

  $('.js-sort-option').each((i, el) => {
    $(el).click(evt => {
      evt.preventDefault();
      const $parent = $(el).closest('.js-sort-container');
      const $list = $parent.find('.js-sort-list');
      const $btn = $parent.find('.js-sort-btn');

      $btn.removeClass(c.isActive);
      $list.removeClass(c.isActive);
    });
  });



  $('.js-product-slider').each((i, el) => {
    const $parentElem = $(el).closest('.js-product');
    const $imgHolder = $parentElem.find('.js-product-img');
    const $container =  $(el).find('.js-product-slider-container');
    const $nextBtn = $(el).find('.js-product-slider-next');
    const $prevBtn = $(el).find('.js-product-slider-prev');

    const slidesNum = $container.find('.swiper-slide').length;


    function changeImage(src) {
      $imgHolder.addClass(c.isInvisible);

      $imgHolder.one('transitionend', () => {
        $imgHolder.find('img').remove();
        const $img = $(`<img src="${src}" />`);
        $imgHolder.find('.js-img-wrapper').append($img);
        $img.one('load', () => {
          objectFitImages($img);
          $imgHolder.removeClass(c.isInvisible);
        });
      });
    }



    const slider = new Swiper($container[0], {
      init: false,
      direction: 'vertical',
      slidesPerView: 3,
      spaceBetween: 15,
      loop: slidesNum > 2,
      noSwiping: slidesNum < 3,
      noSwipingClass: 'swiper-slide',
      navigation: {
        nextEl: $nextBtn,
        prevEl: $prevBtn
      },
      on: {
        init() {
          $container.find('.js-slide-btn').each((j, btn) => {
            $(btn).click(evt => {
              evt.preventDefault();
              const $parentEl = $(btn).closest('.swiper-slide-active');
              if ($parentEl.length) {
                this.slidePrev();
              } else if ($(btn).closest('.swiper-slide').prev().hasClass('swiper-slide-active') && slidesNum > 2) {
                 changeImage($(btn).parent().find('img').data('img'));
                return;
              } else {
                this.slideNext();
              }
              changeImage($(btn).parent().find('img').data('img'));
            });
          });

          if (slidesNum < 3) {
            $nextBtn.hide();
            $prevBtn.hide();
          }

          if (slidesNum < 3) {
            $container.addClass('product_few-slides');
          }

          const $activeSlide = $(this.slides[this.activeIndex]);
          const $targetSlide = $activeSlide;
          changeImage($targetSlide.find('img').data('img'));
        },
        slideChangeTransitionStart() {

          const $activeSlide = $(this.slides[this.activeIndex])
          const $targetSlide = $activeSlide;
          changeImage($targetSlide.find('img').data('img'));
        }
      }
    });

    slider.init();
  });


  $('.js-product-img').each((i, el) => {

    // if (isTouchDevice) return;

    const $holder = $(el).find('.js-img-holder');
    $(el).on('mouseenter', evt => {

        if ($holder.data('scaled')) return;
        $holder.data('scaled', true);

        $holder.data('animating', true);
        TweenMax.to($holder, 0.5, {
          scale: 2,
          onComplete() {
            $holder.data('animating', false);
          }
        });
    });
    $(el).on('mousemove touchmove', evt => {

      let pageX = evt.pageX;
      let pageY = evt.pageY;

      if (evt.type === 'touchmove') {
        pageX = evt.changedTouches[0].pageX;
        pageY = evt.changedTouches[0].pageY;
      }

      if  (!$holder.data('scaled')) {
        TweenMax.to($holder, 0.5, {
          x: 0,
          y: 0
        });
         return;
      }
      const signX = -1;
      const signY = -1;

      const deltaX = signX * (pageX - $(el).offset().left - $(el).width() / 2);
      const deltaY = signY *(pageY - $(el).offset().top - $(el).height() / 2);
      TweenMax.to($holder, 0.5, {
        x: deltaX,
        y: deltaY
      });
    });

    $(el).mouseleave(() => {
       if (!$holder.data('scaled')) return;
       $holder.data('scaled', false);
      TweenMax.to($holder, 0.5, {
        scale: 1,
        x: 0,
        y: 0,
        onComplete() {

          $holder.data('animating', false);
        }
      });
      // $holder.removeAttr('style');
      // setTimeout(() => {
      //   $holder.addClass('with-transition');
      //   $holder.removeAttr('style');
      //   $holder.one('transitionend', () => { $holder.removeClass('with-transition');});
      // }, 100);


    });
  });
}
