import conf from '../_config';

const { b, d } = conf;

export default function () {
  $('.js-sticky-group').each((i, el) => {
    const $parent = $(el).closest('.js-scrollable');
    const $article = $(el).closest('.js-sticky-anchor');

    let enteredFrom = 'top';

    $parent.on('ps-scroll-up ps-scroll-down', evt => {
      if (d.win.width() <= b.md) {
        $(el).removeAttr('style');
        return;
      }
      const evtType = evt.type;
      const winHeight = d.win.height();
      let top = 0;
      let conditionTop = false;
      let conditionBottom = false;
      let conditionFromBottom = false;
      let conditionFromBottomStart = false;

      console.log(enteredFrom);

      if ($(el).data('sticky-position') === 'center') {
       conditionTop = $article.offset().top - 0.5 * $(el).height() < 0;
       conditionBottom = $article.offset().top + $article.height() > winHeight;

       if ($parent.scrollTop() > 0) {
        if (conditionTop && conditionBottom) {
          top = -1 * $article.offset().top + (winHeight - $(el).height()) / 2;
         } else if (!conditionBottom) {
            top = $article.height() - (winHeight - $(el).height()) / 2 - $(el).height();
         }
       }
      } else if ($(el).height() >= winHeight) {
        conditionTop = $article.offset().top + $(el).height() - winHeight  <= 0;
        conditionBottom = $article.offset().top + $article.height() - winHeight >= 0;
        conditionFromBottom = $article.offset().top + $article.height() <= $(el).height();
        conditionFromBottomStart =  $article.offset().top + $article.height() > $(el).height();

        if ( $article.offset().top < 0 && evtType === 'ps-scroll-down') enteredFrom = 'top';


        if (conditionTop && conditionBottom && enteredFrom === 'top') {
          top =-1 * $article.offset().top - $(el).height() + winHeight;
        } else if (conditionFromBottom && conditionBottom && enteredFrom === 'bottom') {
          top = $article.height() - $(el).height();
        } else if (conditionFromBottomStart && enteredFrom === 'bottom') {
          top = -1 * $article.offset().top;
        } else if (!conditionBottom) {
            if (evtType === 'ps-scroll-up') {
              enteredFrom = 'bottom';
            } else {
               enteredFrom = 'top';
            }
          top = $article.height() - $(el).height();
        }
      } else {
        conditionTop = $article.offset().top < 0;
        conditionBottom = $article.offset().top + $article.height() > $(el).height();
        if (conditionTop && conditionBottom) {
          top = -1 * $article.offset().top;
        } else if (!conditionBottom) {
          top = $article.height() - $(el).height();
        }
      }

      if (top < 0) top = 0;

      $(el).attr('style', `transform: translateY(${top}px)`);

    });

  });
}
