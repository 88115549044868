export default {
  b: {
    md: 1023,
    sm: 766,
    xs: 599
  },
  c: {
    isActive: 'is-active',
    isInvisible: 'is-invisible',
    isFilled: 'is-filled'
  },
  d: {
    doc: $(document),
    win: $(window),
    body: $('body')
  }
}
