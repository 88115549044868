import conf from "../_config";

const { c } = conf;

export default function () {
  $('.js-map-switcher').each((i, el) => {
    $(el).click(evt => {

      evt.preventDefault();
      const $activeMap = $('.js-map.is-active');
      const mapIndex = $(el).data('map');
      if ($activeMap.data('map') === mapIndex) return;

      $('.js-map-switcher').removeClass(c.isActive);

      $(el).addClass(c.isActive);

      $activeMap.removeClass(c.isActive);
      $activeMap.one('transitionend', () => {
        $(`.js-map[data-map="${mapIndex}"]`).addClass(c.isActive);
      });


    })
  });
}
