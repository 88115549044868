/* eslint-disable */
import conf from "../_config";
import { throttle } from 'lodash'

const {b, c, d} = conf;

export default  function () {

  const $$nestedLinks = $('.js-nested-links');

   d.body.click(evt => {
      const $target = $(evt.target).closest('.js-nested-links-list, .js-nested-links-btn');
      if (!$target.length) {
        $('.js-nested-links-list').removeClass('is-active');
      }
    });

  $$nestedLinks.each((i, el) => {
    const $btn =  $(el).find('.js-nested-links-btn');
    const $list = $(el).find('.js-nested-links-list');
    let active = false;
    const checkHover = throttle((evt) => {
      const $target = $(evt.target).closest('.js-nested-links');
      if (!$target.length || $target[0] !== el) {
        active = false;
        $list.removeClass(c.isActive);
        $(document).off('mousemove', checkHover);
      }
    }, 200);


    $btn.click(evt => {
      if (!$btn.is('a')) {
        evt.preventDefault();
        $list.toggleClass(c.isActive);
      } else {
        if (!window.matchMedia('(hover: hover)').matches && d.win.width() > b.sm) {
          evt.preventDefault();
          $list.toggleClass(c.isActive);
        }
      }
    });



    $btn.on('mouseenter', () => {
      if (!window.matchMedia('(hover: hover)').matches) return;
      if (active) return;
      $list.addClass(c.isActive);
      active = true;
      setTimeout(() => {
        $(document).on('mousemove', checkHover);
      }, 500);
    });
  });
}
